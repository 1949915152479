@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body {
  font-family: Montserrat;
  background: url("./images/bg3.png") fixed;
  background-size: cover;
}
.background {
  background: url("./images/bg2.png") fixed;
  background-size: cover;
}
.MuiDataGrid-root {
  max-height: calc(100% - (200px));
}
.MuiDataGrid-main {
  background-color: #2D3942;
  color: #9d9999;
}
.MuiDataGrid-columnHeaders {
  background-color: #2D3942;
  color: #ffffff;
}

.MuiDataGrid-toolbarContainer{
  background-color: #2D3942;
  color: #ffffff;
}
.MuiDataGrid-row {
  background-color: #49565f;
  color: #efefef;
}
.MuiDataGrid-row:hover {
  color: #ffffff !important;
}
.MuiDataGrid-footerContainer {
  background-color: #2D3942;
  color: #ffffff !important;
}
.MuiTablePagination-root {
  color: #ffffff !important;
}
.MuiDataGrid-sortIcon {
  opacity: 1;
  color: #ffffff !important;
}
.MuiDataGrid-menuIconButton {
  opacity: 1;
  color: #ffffff !important;
}
.MuiDataGrid-withBorderColor {
  border-color: #9e9e9e !important;
}

.amplify-flex {
  display: none;
}
/* #navigation a.active{
  color: #2D3942;
  background: black;
} */

#navigation li:hover {
  color: #fff;
  background: #000;
  height: 2.5em;
}
#navigation li {
  color: #ffffff;
}
li:has(.active) {
  background: #000;
  color: #fff !important;
  height: 2.5em;
  /* border-bottom: 3px solid #FE001A; */
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.loader {
  width: 40px;
  height: 96px;
  display: inline-block;
  left: 5px;
  position: relative;
  border: 2px solid #fff;
  box-sizing: border-box;
  animation: animloader 2s linear infinite alternate;
  color: #fe001a;
  border-radius: 0 0 4px 4px;
  transform: perspective(140px) rotateX(-45deg);
}

@keyframes animloader {
  0% {
    box-shadow: 0 0 inset;
  }
  100% {
    box-shadow: 0 -70px inset;
  }
}